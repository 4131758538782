
.div001,.div0011 ,.div0012{
    height:calc(100vh - 100px);
    width: 100vw;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: whitesmoke;

padding: 100px auto;
}


@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.div001{
    background-image: url('../Assets/bg13.jpg');
    color: whitesmoke;


}
 .calltoaction{
color: whitesmoke !important;
}
.div0011{
    background-image: url('../Assets/bg14.jpg');
    color: whitesmoke;
}
.div0012{
    background-image: url('../Assets/bg15.jpg');
    color: whitesmoke;

}
.div002a{
  height:calc(100vh - 100px);
  display: flex;
  background-color:#0003049c;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* margin: 100px auto; */
  margin: auto;
}
.div002{
  
  display: flex;
  background-color: transparent;
  flex-direction: column;
  justify-content: center;
  margin-left: 5%;

}
.header{
  display: flex;
  justify-content: center;
  font-size: 3rem;
  font-family: 'Cinzel', serif;
  width: 500px;
  margin:0 auto;
  align-items: center;
  max-width: 90vw;
  animation: fadeIn 3s;
}
.subheader{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  font-family: 'DM Sans', serif;
  font-family: 'Lexend Deca', sans-serif;
  width: 500px;
  margin:0 auto 0 auto;
  max-width: 90vw;
  animation: fadeIn 5s;
}
.calltoaction{
  display: flex;
  color: #0d171afa;
  flex-direction: column;
  /* margin: 30px auto auto auto; */
  margin-top: auto;
  animation: fadeIn 5s;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 15px;
  font-weight: 500;
}
.div0011 .calltoaction{
  color: inherit;
}
.calltoaction button{
  width: 150px;
  height: 40px;
  color: aliceblue;
  border: none;
  font-weight: bolder;
  background-color: #001c28;
  box-shadow: 3px 4px 9px 0px #0d171a12;
  border-radius: 100px;
  /* margin:10px auto auto auto; */
  margin-top: 10px;
  font-size: 15px;
  font-family: 'DM Sans',sans-serif;
  font-family: 'Lexend Deca', sans-serif;

}
.band{
    display: none;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    justify-content: space-evenly;
    margin:  50px auto 0 auto;
  }
  .band button{
    border-radius: 10px;
    /* background-color: rgb(255, 255, 255); */
    background: #c7dddf8d;
    border: none;
    box-shadow: 3px 4px 9px 0px #00000059;
    margin: 20px;
    padding: 5px;
    display: flex;
    justify-content: center;
  }
  .band button img{
    width: 30px;
    height: auto;
  }
  @media (max-width:830px) {
  
    .div001,.div0011 ,.div0012{
      min-height: auto;
      height: auto;
      /* background-size: unset; */
  }
  .div002a{
    min-height: 50vh;
  }
  .header{
    text-align: center;
  }
  }
