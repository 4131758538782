
@import url('https://fonts.googleapis.com/css2?family=Fraunces:wght@100;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Text&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400&display=swap');


::-webkit-scrollbar {
  width: 0.0em;
  height: 0.0em;
  background-color: #F5F5F5;
}
.blogpage{
  display: flex;
  position: relative;
  flex-wrap: wrap;
  z-index: 2;
  background: white;
  /* min-height: calc(100vh - 70px); */
  font-family: 'DM Sans', sans-serif;
  font-weight: 200 !important;
  max-width: 1100px;
  margin: auto;
  color: rgb(27, 27, 27);
  
}
.blogpage a{
  text-decoration: none;
  color: inherit;
}
/* .blogcontainer{
  width: 100%;
  max-width: 1200px;
  margin: auto;
} */
.bloglist{
  width: 100%;
  /* max-width: 400px; */
  max-height: calc(100vh - 70px);

  /* overflow-y: scroll; */
  overflow-x: scroll;
  max-width: 100vw;
  flex-direction: row;
  /* box-shadow: 1px 2px 10px 3px rgba(0, 0, 0, 0.126); */

  /* background-color: rgb(255, 247, 238); */
  /* border-right: 1px solid rgba(0, 0, 0, 0.284); */

}
.blogpage h2{
  font-family: 'Fraunces', serif;
  font-size: 1.4rem;
  margin-top: 50px;
}
.blogpage h3{
  font-family: 'Fraunces', serif;
  font-size: 1.0rem;
}
.bloglist span{
  display: block;
  height: 1px;
  width: 100%;
  max-width: 200px;
  margin: auto;
  color: black;
  background-color: black;
}
.blogdetails span{
  display: block;
  height: 1px;
  width: 100%;
  max-width: 200px;
  margin: auto;
  color: black;
  background-color: black;
}
.blogdetails img{
  margin: 20px auto;
  width: 100%;
  max-width: 600px;
  height: 300px;
  object-fit: cover;

}
.blogdetails p{
 width: 95%;
 margin: auto auto 50px auto;
 max-width: 600px;
 text-align: left;

}
.bloglist ul{
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 95%;
  width: 100%;
  margin-top: 10px;
  overflow-x: scroll;
}
.blogitem{
  width: auto;
  height: auto;
  margin: 20px auto;
  background-color: transparent;
  border-radius: 10px;
  padding: 10px;
  /* box-shadow: 1px 2px 10px 3px rgba(0, 0, 0, 0.126); */
}
.bloglist img{
margin: auto ;
  width: 200px;
  height: 150px;
  object-fit: cover;
}
.blogdetails {
  width: 100%;
  /* padding-top: 100px; */
  min-height: 50vh;
  max-height: calc(100vh - 70px);
  overflow-y: scroll;
  /* margin-left: 0; */
  /* float: left; */
}

@media (min-width: 768px) {
  .bloglist {
    width: 30%;
    max-width: none;
    /* max-height: 100vh; */
   

    /* float: left; */
  }
  .blogdetails img{
    /* margin: 20px auto;
    width: 100%;
    max-width: 600px; */
    height: 500px;
    /* object-fit: cover; */
  

  }
  .bloglist ul{
    display: flex;
    flex-direction: column;
  }
 
  .blogdetails{
    width: 70%;

    /* margin-left: 30%; */
    /* float: left; */
  }

}
