.modal{
    background: rgba(255, 255, 255);
    width: 100vw;
    height: 100vh;
    position: fixed;
    scroll-behavior: none;
    top: 0;
    left: 0;
    z-index: 5;
    display: flex;
    transition: all 0.15s ease;
    font-family: 'Dm', sans-serif;
    font-family: 'Lexend Deca', sans-serif;
    overflow-y: scroll;
}
.modalcontent{
      /* background: linear-gradient( 45deg,#f4dede , #f77117 );  width: 400px;
      max-width: 90%;
      margin: auto;
      position: relative;
      border: 8px solid white;
      height: auto;
      min-height: 300px;
      display: flex;
      color: rgb(12, 8, 43);
      flex-direction: column;
      justify-content: center;
      box-shadow: 20px 20px 20px 0px rgb(0 0 0 / 10%);
      transition: all 0.25s ease; */

}
.modalheader, .modalfooter{
    /* color: red;
    padding: 10px; */
} 
.modaltitle{
    /* margin: 0; */
}
.modalheader h1{
    /* font-size: 32px;
    color: rgb(228, 235, 238);
    text-align: left;
    margin-top: 30px;
    background-color: transparent; */
}
.modalbody{
    /* background:transparent ;
    padding: 10;
    border: 0px solid cadetblue;
    margin: auto 10px; */
}
.modaltitle h1{
    /* font-size: 2.5rem;
    color: red; */
}
/* 
.img001{
    width: auto;
    height: 150px;
    border-radius: 0;
    object-fit: cover;
    margin:10px 5%;
}
.modalbodycontainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}
.modalbodytext{
    display: flex;
    justify-content: flex-start;
    flex-direction:column ;
    align-items: center;
    background-color: transparent;
    align-content: left;
    align-items: flex-start;
    padding-left: 5%;
}
.modalbodytext p{
    font-size: .8rem;
    font-weight: 400;
    margin: 10px auto 10px 0;
    color: white;
}
.modalbodytext p span{
    margin: 0 10px 0 0;
}
.modalbodytext h2{
    font-size: 0.7REM;
    font-weight: 600;
    color: white;
    font-family: 'DM Sans', sans-serif;
} */

.div002{
    margin: auto;
    width: auto;
    /* max-width: 80%; */
    /* border: 8px solid white; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    /* border-radius: 20px;
    box-shadow: 3px 4px 9px 5px rgb(14 13 13 / 10%); */
    font-family: 'DM Sans', sans-serif;
    font-family: 'Lexend Deca', sans-serif;
    background: transparent;
    max-width: 700px;
    padding: 20px 10px;
}

.img0012{
    width: auto;
    height: 70px;
}
.div0021 .text {
    margin: auto;
    font-size: 14px;
    margin: auto 5px;
    text-align: justify;

}
.div0021 .text h2{

    margin: 10px auto 0px auto;
    font-size: 1.1rem;
}
.img001,.img002{
    width: 100%; 
    max-width: 500px;
    height: 300px;
    object-fit: cover;
}
@media screen and (max-width: 486px){
    .img001,.img002{
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    margin: auto auto;
    }
    .div002{
        height: auto;
        max-width: 90%;
    }
    
}
.button001 {
       /* min-width: 165px; */
       font-weight: 600;
       letter-spacing: 1px;
       background-color: #001c28;
       color: white;
       border: none;
       padding: 7px 24px;
       margin: 18px auto;
       border-radius: 19px;
}
.button001 a{
    text-decoration: none;
    color: aliceblue;
}
.closeBtn{
    position: absolute;
    top: 10px;
    right: 10px;
    font-weight: 600;
    font-size: 1rem;
    background-color: #001c28;
    color: white;
    border: none;
    padding: 10px 10px;
    margin-bottom: 18px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    transition: all 0.25s ease;
    cursor: pointer;
}
.button001 {
    min-width: 165px;
    font-weight: 600;
    background-color: #001c28;
    color: white;
    border: none;
    padding: 12px 24px;
    margin-bottom: 50px;
    border-radius: 19px;
    cursor: pointer;
    font-family: 'DM Sans',sans-serif;
    font-family: 'Lexend Deca', sans-serif;
}

  .button001:hover{
    transform: translateY(-5px);
    box-shadow: 0 10px 20px -10px rgba(24,90,219,0.6);
  }
  
  .closeBtn:hover{
    transform: translateY(-5px);
    box-shadow: 0 10px 20px -10px rgba(24,90,219,0.6);
  }
  .modalcont{
    background: transparent;
    margin:auto ;
  }
  .modalcont h1{
    color:#001c28;
    background-color: transparent;
    text-transform: uppercase;
  }
  .serv{
    background-color: transparent;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    font-family: 'Lexend Deca', sans-serif;

  }
  .servhead{
    margin-bottom: 0;
  }
  .sty{
    background-color: red;
    margin: 10px 10px;
    background-color: #001c28;
    padding: 10px 15px;
    text-align: center;
    display: flex;
    color: aliceblue;
    background: transparent;
    min-width: 10px;
    color: #001c28;
    /* height: 50px; */
    border: 2px solid;
    font-size: 14px;
    border-radius: 20px;
    align-content: center;
    align-items: center;
    justify-content: space-around;
    font-family: 'Lexend Deca', sans-serif;
  }