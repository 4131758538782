.div000 {
    width: 100%;
   padding: 0 0 20px 0;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-size: 1.5rem;
    font-weight: 600;
    font-family: 'Dm', sans-serif;
    font-family: 'Lexend Deca', sans-serif;
  }
  h1{
    color: #5d5b5b;
  }

  .button{
    padding: 10px 20px;
    font-size: 14px;
    /* font-size: 22px; */
    /* background-color: #ff6b06; */
    /* opacity: 0.8; */
    /* color: white; */
    border: none;
    background-color: #001c28;
    font-family: 'DM Sans',sans-serif;
    font-family: 'Lexend Deca', sans-serif;
    border-radius: 20px;
    color: aliceblue;
  }