:root{
    --primary:rgb(12, 8, 43);
}
#menu{
    right: 20px;
    font-size: 4em;
    font-weight: 800;
    cursor: pointer;
    text-align: center;
    transition: all 0.5s;
    -webkit-transition: all 0.5s ease-in-out;
    position: absolute;
    font-family: 'Dm sans', sans-serif;
}

#menu:hover{
    transform: rotate(90deg);
    color: #ffffff;
}

ul{
    list-style: none;
    padding: 0;
    margin-top: 70px;
   
}

.li121{
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    height: 70px;
    line-height: 70px;
    margin: 15px 0;
    color: rgb(105, 104, 104);
    cursor: pointer;
    background-color: transparent;
    font-size: 1em;
    letter-spacing: 0.2em;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
}

.li121:hover{
    background-color: #f87700;
    color: black;
    letter-spacing: 0.3em;
    font-size: 1.5em
}

#lgMenu{
    width: 360px;
    height: 100%;
    top: -1vh;
    right: 400px;
    overflow:hidden;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 1px 1px grey;
    position: absolute;
    z-index: 111;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;   
    display: none; 
}


#lgMenu123{
    width: 360px;
    height: 100%;
    left: -400px;
    top:-1vh;
    overflow:hidden;
    background-color: var(--primary);
    box-shadow: 0px 0px 1px grey;
    z-index: 10000;
    position: fixed;
    opacity: 0;
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    opacity: 1;
    left: 0;
    position:fixed;
}


#lgMenu123:hover{
background-color: rgb(12, 8, 43,0.5);

}

#exit{
    position: absolute;
    right: 10px;
    padding: 0px;
    top: -10px;
    font-size: 4em;
    color: #b8b5b5;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.5s;
}
#exit:hover{
    color: black
}

#lgMenu.enter{
    opacity: 1;
    left: 0;
}


.navlink0001{
    text-decoration: none;
    color: inherit;
}


.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgb(12, 8, 43,0.8);
  }