.div001{
    max-width: 900px;
    width: 98%;
    margin: auto;
    height: auto;
    overflow:hidden;
    background-color: transparent;
    border: 0px solid #d23211;
    z-index: 100;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
    opacity: 1;
    position:relative;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:center;
}



.div001close{
    width: 0;
    height: 400px;
    left: 0%;
    padding: 0px;
    top: -100vh;
    overflow:hidden;
    background-color:  rgb(174, 48, 48);
    box-shadow: 2px 2px 10px rgb(85, 84, 84);
    z-index: 100;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
    opacity: 1;
    position:fixed;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
}
.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
    height: 100vh;
    z-index: 10;
    background: rgb(255, 255, 255);
    width: 100vw;
    height: 100vh;
    position: fixed;
    scroll-behavior: none;
    top: 0;
    left: 0;
    z-index: 5;
    display: flex;
    transition: all 0.15s ease;
    font-family: 'Dm', sans-serif;
    overflow: scroll;
}
@media (max-width:869px) {
    .div001{
        padding: 100px 0;
    }
    
}