

.form{
    background-color: transparent;
    color: #ff6b06;
    /* border: 2px solid; */
    /* padding: 2.3% 3.0% 0.7% 3.0%; */
    /* width: 28%; */
    width: 250px;
    max-width: 90%;
    margin: auto;
    margin-top: 20px;
    /* height: 20rem; */
    border-radius: 20px;
    display: flex;
    /* box-shadow: 9px 9px 19px 0px #0000001a; */
    flex-direction: column;
    padding: 0px 10px;

}
.div001{
    display: flex;
    margin: 0 10px;
    flex-direction: column;
    text-align: left;
}

.div001 label{
 font-size: 13px;
 margin:5px 0% 5px 0%;
 color: rgb(53, 51, 51);
 text-align: left;
}

.div001 select{
 width: 100%;
 max-width: 250px;
 border-radius: 0;
 padding: 10px 20px;
 font-size: 13px;
 color: #57524f;
 border-bottom:1px solid #001c28;
 background-color: transparent;
-moz-appearance:none; /* Firefox */
-webkit-appearance:none; /* Safari and Chrome */
 appearance:none;
 background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
 background-repeat: no-repeat;
 background-position-x: 90%;
 background-position-y: 50%;
 font-family: 'Lexend Deca', sans-serif;
}


  
.form button{
    margin: 0 auto;
    border: 2px solid #001c28;
    border: none;
    border-radius: 20px;
    background-color: white;
    /* border: none; */
    color: #e55829;
    color: #001c28;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    padding: 10px 20px;
    font-weight: 600px;
    font-family: 'DM SANS',sans-serif;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 14px;
    box-shadow: 9px 9px 19px 0px #0000001a;
}
.form .sub{
    background-color: white;
    min-width: 120px;
    width: auto;
    text-align: center;
    color: #001c28;
    box-shadow: 9px 9px 19px 0px #0000001a;
    padding: 10px 20px;
    height: auto;
    border-radius: 100px;
    font-size: 14px;
    font-family: 'Lexend Deca', sans-serif;
    border: none;
}

.slider {
    color: #eb6a2e;
  }
  

.slider .MuiSlider-thumb{
    background-color:#2b1f58
}


.slider .MuiSlider-rail{
    opacity: 1;
    background-color:#2b1f58
}
  
 