

.div0000{
    padding: 0px 0 10px 0;
    background: #ff6b06;
    background: #f5f5f5;
    background: transparent;
    /* background-image: url(../Assets/dezert1.jpg); */
    /* background: linear-gradient(45deg, rgb(12 8 43), #ff6b06); */
    background-attachment: fixed;
    background-position: center;
    background-size: contain;
    background-color: transparent;
    /* background-color: aliceblue; */
    /* box-shadow: inset 3px 9px 19px 1px #00000016; */
}
::-webkit-scrollbar { 
    width: 0.2em;
    background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb {
  background-color: #000000;
}
.div00{
    display: flex;
    justify-content: space-evenly;
    margin: 0px auto 30px AUTO;
    flex-wrap: wrap;
    height: 420px;
    overflow-y: scroll;
    padding: 100px 0;
    max-width: 1150px;
    scrollbar-width: none;
   
}
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

.div001{
    width: 280px;
    height: auto;
    border-radius: 10px;
    background-color: aliceblue;
    background-color: transparent;
    /* padding: 20px 0;
    background-color: rgb(43, 42, 42); */
   
    display: flex;
    flex-direction: column;
    margin: 30px 30px;
    justify-content: space-around;
    
    align-items: center;
    flex-direction: column;
    animation: fadeIn 3s;
}

.div002{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.129);
}

.div0030{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.div003{
       /* width: 90%; */
       display: flex;
       justify-content: flex-start;
       margin: 10px;
}
.img000{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    /* margin-top: 10px; */
}
.div004{
    width: 98%;
    text-align: left;
    color: rgb(3, 3, 3);
    color: #080808;
    margin:auto;
    font-size: 14px;
    font-family: 'Lexend Deca', sans-serif;
}
.div004 p{
    padding: 10px;
}

.div005{
    /* margin: auto auto 20px auto; */
    text-align: left;
    font-family: 'DM Sans', sans-serif;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 14px;
}
.div005 h3,h5{
    margin: 0;
    padding-left: 10px;
    
    color:#8c8c8c;
}
.head{
    font-size: 1.4rem;
    text-align: left;
    padding: 10px 20px;
    border-radius: 0 25px 25px 0;
    width: -moz-fit-content;
    width: fit-content;
    color:whitesmoke;
    background-color: #ff6b06;
    background-color: #001c28;
    box-shadow: 0px 5px 20px rgb(44 43 43,.5);
    font-family: 'DM Sans', sans-serif;
    font-family: 'Cinzel', serif;
    font-weight: 800;
}
.div006{
    width: 100%;
    height: auto;
    background-color: transparent;
    /* background-color: rgb(0 0 0 / 40%); */
    border-radius: 10px;
    /* box-shadow: 0px 5px 20px rgba(147, 144, 144, 0.459);  */
    padding: 0 0;
}