.div001{
    font-size: 32px;
    color: rgb(51, 50, 50);
    margin: 50px auto 10px auto;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    font-family: 'Lexend Deca', sans-serif;
    }
    .div000{
      
      
  }

.div002{
  display: flex;
    
  margin: auto;
    width: 90%;
    max-width: 850px;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(189 255 248);
    background-color: transparent;
    padding: 100px 0;
}


.div003{
    text-align: left;
    width: 450px;
    max-width: 90%;
    color: rgb(0, 0, 0);
    font-family: 'DM Sans', sans-serif;
    font-family: 'Lexend Deca', sans-serif;
}

.div003 h2{
    color: rgb(0, 0, 0);
    text-align: left;
}

.div003 p{
  line-height: 22px;
  text-align: left;
  font-size: 14px;
  color: whitesmoke;
  color: #5d5b5b;
}

  .img001{
    height: 320px;
    width: 300px;
    max-width: 90%;
    object-fit: cover;
    /* border: 8px solid whitesmoke; */
    /* border-radius: 20px;
    box-shadow: 3px 4px 9px 0px #070a0a12; */
  }  


  .div003 button{
    width:auto;
    height: auto;
    padding: 10px 20px;
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
    font-family: 'Lexend Deca', sans-serif;
    background-color:  #001c28;
    color: white;
    border: none;
    border-radius: 20px;
    box-shadow: 3px 4px 9px 0px #0d171a12;
    

  }