
.div000{
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    background-image: url('../../Assets/treeforest.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.div001{
       /* width: 35vw; */
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    margin: auto;
    justify-content: center;
    align-items: center;
    align-content: center;
}



.img001{
    width: 200px;
    position: relative;
    top: 0;
}


.form{
    background-color: rgb(0 0 0 / 79%);
    color: azure;
    padding: 0;
    /* height: 28rem; */
    /* border-radius: 4%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    max-width: 95%;
}

/* .form h1{
    font-size: 45px;
    font-weight: 400;
    text-align: center;
} */


.div001a{
    padding: 0;
    display: flex;
    background: transparent;
    margin: auto;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 95%;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

.div001a label{
 font-size: 1rem;
 margin:auto;
 font-weight: 380;
 display: flex;
 width: 90%;
 align-items: flex-start;
}

.div001a input{
 width: 350px;
 max-width: 90%;
 height: 30px;
 border-radius: 20px;
 padding-left:0;
 border: none;
 color: rgb(102, 99, 99);
 background-color: rgba(204, 212, 216, 0.753);
}


  
.form button{
    border-radius: 20px;
    background-color: #eb6a2e;
    border: none;
    color: rgb(255, 255, 255);
    margin:30px auto;
    letter-spacing: 1px;
    font-weight: 600;
    padding: 10px 32px;
    font-family:"Dm Sans", sans-serif;
}
