
:root{
    --primary:whitesmoke;
}
.div000{
    margin:  auto;
    width: 100%;
    background-color: rgb(219, 239, 252,0.7);
    font-family:'DM Sans', sans-serif ;

}
.div000 .p{
    width:90% ;
    max-width: 1150px;
    text-align: left;
    background-color: var(--primary) ;
    margin:0 auto;
    padding-bottom: 50px;
}
.div000 .p p{
width: 90%;
margin: auto;
}
.div000 .p h1{
    font-size: 2.5rem;
    background-color: var(--primary);
    border: none;
    width: 90%;
    margin: auto;
    padding-top: 120px;
    margin-bottom: 15px;
    font-family:  'DM Sans', sans-serif;
}
.div000 h1{
    background: var(--primary);
    margin: 0 auto;
    width: 90%;
    max-width: 1150px;
}
.div001{
    display: flex;
    /* margin-top: 1%; */
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    width: 90%;
    max-width: 1150px;
    margin: auto auto 50px auto;
    background: var(--primary);
}

.div002{
    margin: 50px 30px;
     height: 230px;
    width: auto;
    max-width: 80%;
    /* border: 8px solid white; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 20px;
    box-shadow: 3px 4px 9px 5px rgb(14 13 13 / 10%);
    font-family: 'Lato', sans-serif;
    background: whitesmoke;
}
.div0021{
    width: 200px;  
    color: rgb(0, 11, 21);
    height: 90%;
    margin: auto;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.img0012{
    width: auto;
    height: 70px;
}
.div0021 .text {
    margin: auto;
    font-size: 14px;
    margin: auto 5px;

}
.div0021 .text h2{

    margin: 10px auto 0px auto;
    font-size: 1.1rem;
}
.img001,.img002{
    width: 150px;
    height: 100%;
    min-height: 100%;
    border-radius: 20px 0 0 20px;
    margin: auto auto;
}
@media screen and (max-width: 486px){
    .img001,.img002{
    width: 100%;
    height: 160px;
    object-fit: cover;
    /* min-height: 100%; */
    border-radius: 20px 20px 0 0px;
    margin: auto auto;
    }
    .div002{
        height: auto;
        max-width: 250px;
    }
    .div0021{
        width: 200px;
        background-color: #BB2649;
        background: transparent;
        color: rgb(0, 11, 21);
        height: 90%;
        margin-top: 20px;
        margin: auto;
        margin-top: 20px;
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    
}
.button001 {
       /* min-width: 165px; */
       font-weight: 600;
       letter-spacing: 1px;
       background-color: #ff8800;
       color: white;
       border: none;
       padding: 7px 24px;
       margin: 18px auto;
       border-radius: 19px;
}
.button001 a{
    text-decoration: none;
    color: inherit;
}
.primaryBtn{
    margin: 20px 10px;
    cursor: pointer;
    font-weight: 500;
    padding: 13px 26px;
    border-radius: 15px;
    font-size: 0.8rem;
    border: none;
    color: white;
    background: #185adb;
    transition: all 0.25s ease;
  }
  .primaryBtn:hover{
    transform: translateY(-5px);
    box-shadow: 0 10px 20px -10px rgba(24,90,219,0.6);
  }
  #schvisa{
    width: 500px;
  }