.main{
    min-height: calc(100vh - 100px);
}

.dashboard {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  button{
    border: none;
    border-radius: 100px;
    padding: 12px 24px;
    background-color: #001c28;
    color: #F5F5F5;
  }
  
  .listContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin: auto auto auto auto !important;
    padding: 20px 0px 20px 0px !important;
    width: 100%;
    background: transparent;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(22, 1, 1, 0.353);

  }
  
  .listItem {
    text-align: center;
    font-size: 2px !important;
    cursor: pointer;
    background: #ff6b06;
    border-radius: 20px;
    color: #fff;
    min-width: 100px;
    width: fit-content;
    text-align: center !important;
    width: fit-content !important;
    border: none;
    min-height: 40px;
    padding: 0 !important;
  }
  .listItem:hover{
    background-color: #fff;
    color: #ff6b06;
    border: 1px solid rgba(22, 1, 1, 0.353);

  }
  
  .sectionContainer {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1300;
    /* display: flex; */
    justify-content: center;
    align-items: center;
  }
  
  .sectionPaper {
    width: 100%;
    max-width: 600px;
    padding: 16px;
  }
  
  .active {
    display: block;
  }
  
  .section {
    margin-top: 0;
    padding: 0;
    background-color: #fff;
    display: flex;
    display: flex;
    flex-direction: row;
    width: 100vw;
    /* flex-wrap: wrap; */
  }
  .section .side{
    width:30%;
    background: red;
    min-height: 60vh; 
  }
  .section .details{
    width: 70%;
    background: #000;

  }

  @media (max-width: 768px) {
    .section{
      flex-direction: column;
    }
    .section .side{
      width: 100%;
      min-height: 200px;
      height: auto;
    }
    .section .details{
      width: 100%;
      background: #000;
      min-height: 500px;  
    }
  }




  /* blog details */
  
@import url('https://fonts.googleapis.com/css2?family=Fraunces:wght@100;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Text&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400&display=swap');


::-webkit-scrollbar {
  width: 0.0em;
  height: 0.0em;
  background-color: #F5F5F5;
}
.blogpage{
  display: flex;
  position: relative;
  flex-wrap: wrap;
  z-index: 2;
  background: white;
  /* min-height: calc(100vh - 70px); */
  font-family: 'DM Sans', sans-serif;
  font-weight: 200 !important;
  max-width: 1100px;
  margin: auto;
  color: rgb(27, 27, 27);
  
}
.blogpage a{
  text-decoration: none;
  color: inherit;
}
/* .blogcontainer{
  width: 100%;
  max-width: 1200px;
  margin: auto;
} */
.bloglist{
  width: 100%;
  /* max-width: 400px; */
  max-height: calc(100vh - 70px);

  /* overflow-y: scroll; */
  overflow-x: scroll;
  max-width: 100vw;
  flex-direction: row;
  /* box-shadow: 1px 2px 10px 3px rgba(0, 0, 0, 0.126); */

  /* background-color: rgb(255, 247, 238); */
  /* border-right: 1px solid rgba(0, 0, 0, 0.284); */

}
.blogpage h2{
  font-family: 'Fraunces', serif;
  font-size: 1.4rem;
  margin-top: 50px;
}
.blogpage h3{
  font-family: 'Fraunces', serif;
  font-size: 1.0rem;
}
.bloglist span{
  display: block;
  height: 1px;
  width: 100%;
  max-width: 200px;
  margin: auto;
  color: black;
  background-color: black;
}
.blogdetails span{
  display: block;
  height: 1px;
  width: 100%;
  max-width: 200px;
  margin: auto;
  color: black;
  background-color: black;
}
.blogdetails img{
  margin: 20px auto;
  width: 100%;
  max-width: 600px;
  height: 300px;
  object-fit: cover;

}
.blogdetails p{
 width: 95%;
 margin: auto auto 50px auto;
 max-width: 600px;
 text-align: left;

}
.bloglist ul{
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 95%;
  width: 100%;
  margin-top: 10px;
  overflow-x: scroll;
}
.blogitem{
  width: auto;
  height: auto;
  margin: 20px auto;
  background-color: transparent;
  border-radius: 0px;
  padding: 10px;
  border: 1px solid rgba(85, 83, 83, 0.319);
  /* box-shadow: 1px 2px 10px 3px rgba(0, 0, 0, 0.126); */
}
.bloglist img{
margin: auto ;
  width: 200px;
  height: 150px;
  object-fit: cover;
}
.blogdetails {
  width: 100%;
  /* padding-top: 100px; */
  min-height: 50vh;
  max-height: calc(100vh - 70px);
  overflow-y: scroll;
  /* margin-left: 0; */
  /* float: left; */
}

@media (min-width: 768px) {
  .bloglist {
    width: 30%;
    max-width: none;
    /* max-height: 100vh; */
   

    /* float: left; */
  }
  .blogdetails img{
    /* margin: 20px auto;
    width: 100%;
    max-width: 600px; */
    height: 500px;
    /* object-fit: cover; */
  
  }
  .bloglist ul{
    display: flex;
    flex-direction: column;
  }
 
  .blogdetails{
    width: 70%;

    /* margin-left: 30%; */
    /* float: left; */
  }
}



.div0000{
  padding: 0px 0 10px 0;
  background: #ff6b06;
  background: #f5f5f5;
  background: transparent;
  /* background-image: url(../Assets/dezert1.jpg); */
  /* background: linear-gradient(45deg, rgb(12 8 43), #ff6b06); */
  background-attachment: fixed;
  background-position: center;
  background-size: contain;
  background-color: transparent;
  /* background-color: aliceblue; */
  /* box-shadow: inset 3px 9px 19px 1px #00000016; */
}
::-webkit-scrollbar { 
  width: 0.2em;
  background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb {
background-color: #000000;
}
.div00{
  display: flex;
  justify-content: space-evenly;
  margin: 0px auto 30px AUTO;
  flex-wrap: wrap;
  height: 420px;
  overflow-y: scroll;
  padding: 100px 0;
  max-width: 1150px;
  scrollbar-width: none;
 
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.div001{
  width: 280px;
  height: auto;
  border-radius: 10px;
  background-color: aliceblue;
  background-color: transparent;
  /* padding: 20px 0;
  background-color: rgb(43, 42, 42); */
 
  display: flex;
  flex-direction: column;
  margin: 30px 30px;
  justify-content: space-around;
  
  align-items: center;
  flex-direction: column;
  animation: fadeIn 3s;
}

.div002{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.129);
}

.div0030{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.div003{
     /* width: 90%; */
     display: flex;
     justify-content: flex-start;
     margin: 10px;
}
.img000{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  /* margin-top: 10px; */
}
.div004{
  width: 98%;
  text-align: left;
  color: rgb(3, 3, 3);
  color: #080808;
  margin:auto;
  font-size: 14px;
  font-family: 'Lexend Deca', sans-serif;
}
.div004 p{
  padding: 10px;
}

.div005{
  /* margin: auto auto 20px auto; */
  text-align: left;
  font-family: 'DM Sans', sans-serif;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 14px;
}
.div005 h3,h5{
  margin: 0;
  padding-left: 10px;
  
  color:#8c8c8c;
}
.head{
  font-size: 1.4rem;
  text-align: left;
  padding: 10px 20px;
  border-radius: 0 25px 25px 0;
  width: -moz-fit-content;
  width: fit-content;
  color:whitesmoke;
  background-color: #ff6b06;
  background-color: #001c28;
  box-shadow: 0px 5px 20px rgb(44 43 43,.5);
  font-family: 'DM Sans', sans-serif;
  font-family: 'Cinzel', serif;
  font-weight: 800;
}
.div006{
  width: 100%;
  height: auto;
  background-color: transparent;
  /* background-color: rgb(0 0 0 / 40%); */
  border-radius: 10px;
  /* box-shadow: 0px 5px 20px rgba(147, 144, 144, 0.459);  */
  padding: 0 0;
}
.ut{

  background-color: transparent;
  display: grid;
  place-items: center;
}