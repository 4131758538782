@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200&display=swap');
.div000{
  background-color: #e56829;
  background-color: white;
  position: relative;
  z-index: 2;
}
/* .div001 {
  width: 100%;
  background: linear-gradient(180deg,#e56829),rgb(24 33 48),rgb(255, 255, 255);
  min-height: 60vh;
  background-image: url('../Assets/bg1.jpg');
  background-image: url('../Assets/bg4.jpg');
  background-image: url('../Assets/bg5.jpg');
  background-image: url('../Assets/bg7.png');
  background-image: url('../Assets/bg6.jpg');
  background-image: url('../Assets/eifeltower1.jpg');
  
  /* background-image: url('../Assets/Flight.jpg');
  background-image: url('../Assets/travelling-bg.png'); 
  background-repeat: no-repeat;
  display: flex;
  overflow: hidden;
  /* background-size: 40%;
  background-position: 100% 100%;
  background-size: cover;
  background-position: center;
  justify-content: flex-start;
  flex-direction: column;
  align-content: center;
  align-items: center;
  
  } */
  .div001{
    background-color: #001c28;
  }
  @media  (max-width:700px) {

    .div003{
    font-family: 'BebasNeueRegular', sans-serif;
    font-family: 'DM Sans', sans-serif;
    font-family: 'Lexend Deca', sans-serif;

    font-size: 5rem;
   
    color: #e55829;
    text-align: left;
    
    letter-spacing: .2rem;
    font-weight: 900;
  }
  }
.band{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: space-evenly;
  margin: 30px auto;
}
.band button{
  border-radius: 10px;
  /* background-color: rgb(255, 255, 255); */
  background: #727272;
  border: none;
  box-shadow: 3px 4px 9px 0px #00000059;
  margin: 20px;
  padding: 5px;
  display: flex;
  justify-content: center;
}
.band button img{
  width: 50px;
  height: auto;
}

  .div002{
    color: white;
    padding: 5% 0%;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  .div0010{
    width: 100%;
    background-color: rgb(19, 18, 18);
    background-color: rgb(26 34 53);
    background-color: rgb(193 239 249);
    background-color: rgb(255 255 255);
    background: linear-gradient(to bottom,rgb(24 33 48),#e56829),white;
    background: linear-gradient(to bottom,rgb(0 28 40),rgb(203, 212, 226));
    background: linear-gradient(to bottom,rgb(226 172 143 / 55%),rgb(255 255 255));
    background: linear-gradient(to bottom,rgb(89 231 255 / 55%),rgb(255 255 255));
    background: linear-gradient(to bottom,rgb(250 213 158 / 55%),rgb(255 255 255));
    
   
  }
.div0011{
  width: 100%;
  max-width: 1150px;
  margin: auto auto auto auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-direction: row;
  padding: 40px 0 20px 0;
  color: #e56829;
  /* background: linear-gradient(174deg,rgb(24 33 48),#e56829); */
  align-items: center;

}

.div0011a{
  background: #000;
}
.div0011b button{
  width: auto;
  height: auto;
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 14px;
  font-family: 'DM Sans', sans-serif;
  font-family: 'Lexend Deca', sans-serif;
  background-color: #001c28;
  color: white;
  border: none;
  border-radius: 20px;
  box-shadow: 3px 4px 9px 0px #0d171a12;
}

  .div002a{
    color: white;
    margin:10% auto 70px auto; 
    width: 95%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    flex-direction: row;
  }
  .div003a{
    font-family: 'BebasNeueRegular', sans-serif;
    font-family: 'DM Sans', sans-serif;
    font-family: 'Lexend Deca', sans-serif;

    font-size: 2rem;
   
    color: #e56829;
    text-align: left;
    
    letter-spacing: .2rem;
    font-weight: 900;
  }

  .div003{
    font-family: 'DM Sans', sans-serif;
    font-family: 'BebasNeueRegular', sans-serif;
    font-family: 'Cinzel', serif;
   
    font-size: 5rem;
   
    color: #e55829;
    color: #001c28;
    color: aliceblue;
   
    text-align: center;
    
    letter-spacing: .2rem;
    font-weight: 900;
  }
@media screen and (max-width:600px){
  .div003{
    font-size: 3.5rem;
    overflow-x: hidden;
  }

  
}
  .div003p{
    font-size: 16px;
    color: rgb(255, 255, 255);
    width: auto;
    margin-top: 15px;
    text-align: center;
    font-family: 'DM Sans',sans-serif;
    font-family: 'Lexend Deca', sans-serif;

  }
  
  .div003pc{
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 30px;
   color: #ff6b06;

  }
  .div003pc button{
    width: auto;
    height: auto;
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 15px;
    font-family: 'DM Sans', sans-serif;
    font-family: 'Lexend Deca', sans-serif;

    background-color: #ff6b06;
    background-color: #001c28;
    color: white;
    border: none;
    border-radius: 20px;
    box-shadow: 3px 4px 9px 0px #0d171a12;
  }


  
  .div004{
    width: 450px;
    max-width: 90%;
   
  }

  .img001 {
    width: 12%;
    height: 170%;
    margin-top: -2%;
  }
.carousalHeading{
  padding-top: 100px;
  margin: 0 auto;
  font-family: 'Cinzel',serif;
  background: transparent;
  color: #001c28;
  }
.caro{
  background: transparent;
  height: 400px;
  max-width: 1200px;
  margin: 0 auto 70px auto;
  color: rgb(203, 212, 222);
  font-family: 'Lexend Deca', sans-serif;
}
.caroItem{
  width: 230px;
  height: 280px;
  border-radius:0px;
  margin: auto ;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:  0;
  background: rgb(255, 255, 255);
  
}
.caroItem .caroImage{
  height: 100%;
  width: 100%;
  margin: auto;
  border-radius: 0px;
  background: transparent;
}
.caroImage img{
  height: 100%;
  width: 100%;
  border-radius: 0px;
  margin: auto;
  border: 8px solid white;
  box-shadow: 1px 3px 9px 0px rgb(0 0 0 / 25%) ;
}
.caroText{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  position: relative;
  border-radius:  10px 10px 0 0;
  width: 100px;
  top: -10px;
  background-color: rgb(255, 255, 255);
  color: #000;
  font-family: 'Lexend Deca', sans-serif;
font-weight: 800;
font-size: 14px;
letter-spacing: 2px;
}
.div0011a{
  background: transparent;
  color: #ff8800;
  color: #8c8c8c;
  display: flex;
  opacity: 1;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  width: 98%;
  margin: auto;
  max-width: 300px;
  background-image: url('../Assets/travelling-items.png');
  background-image: url('../Assets/travelling-couples.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 200px;
  height: 300px;
}
@media screen and (max-width:600px){
  .div0011a{
   display: inherit;
  }
  
  
}
.div0011b{
  background: transparent;
  color: #ff8800;
  color: #8c8c8c;
  color: #b8a1a1;
  color: #001c28;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 90%;
  margin: auto;
  max-width: 400px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 200px;
  height: auto;
  font-size: 15px;
  font-family: 'Lexend Deca', sans-serif;
}
.container{
  
  background: linear-gradient(360deg,rgb(18, 17, 17),#e56829);
  background: linear-gradient(360deg,rgb(19 18 18),#e5682900);
  background: transparent;
}